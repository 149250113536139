@import '../../style/themes/index';
@import '../../style/mixins/index';

@switch-prefix-cls: ~'@{ant-prefix}-switch';
@switch-duration: 0.36s;

.@{switch-prefix-cls} {
  .reset-component;

  position: relative;
  display: inline-block;
  box-sizing: border-box;
  min-width: @switch-min-width;
  height: @switch-height;
  line-height: @switch-height - 2px;
  vertical-align: middle;
  background-color: @disabled-color;
  border: 1px solid transparent;
  border-radius: 100px;
  cursor: pointer;
  transition: all @switch-duration;
  user-select: none;

  &-inner {
    display: block;
    margin-right: @switch-inner-margin-min;
    margin-left: @switch-inner-margin-max;
    color: @text-color-inverse;
    font-size: @font-size-sm;
  }

  &-loading-icon,
  &::after {
    position: absolute;
    top: 1px;
    left: 1px;
    width: @switch-height - 4px;
    height: @switch-height - 4px;
    background-color: @switch-bg;
    border-radius: 18px;
    cursor: pointer;
    transition: all @switch-duration @ease-in-out-circ;
    content: ' ';
  }

  &::after {
    box-shadow: 0 2px 4px 0 @switch-shadow-color;
  }

  &:not(&-disabled):active::before,
  &:not(&-disabled):active::after {
    width: 24px;
  }

  &-loading-icon {
    z-index: 1;
    display: none;
    font-size: 12px;
    // loading default use animation
    // animation: loadingCircle 1s infinite linear;
    background: transparent;
    svg {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin: auto;
    }
  }

  &-loading &-loading-icon {
    display: inline-block;
    color: rgba(0, 0, 0, 0.65);
  }

  &-checked&-loading &-loading-icon {
    color: @switch-color;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0 0 2px fade(@switch-color, 20%);
  }

  &:focus:hover {
    box-shadow: none;
  }

  &-small {
    min-width: @switch-sm-min-width;
    height: @switch-sm-height;
    line-height: @switch-sm-height - 2px;

    .@{switch-prefix-cls}-inner {
      margin-right: 3px;
      margin-left: 18px;
      font-size: @font-size-sm;
    }

    &::after {
      width: @switch-sm-height - 4px;
      height: @switch-sm-height - 4px;
    }

    &:active::before,
    &:active::after {
      width: 16px;
    }
  }

  &-small &-loading-icon {
    width: @switch-sm-height - 4px;
    height: @switch-sm-height - 4px;
  }

  &-small&-checked {
    .@{switch-prefix-cls}-inner {
      margin-right: 18px;
      margin-left: 3px;
    }
  }

  &-small&-checked &-loading-icon {
    left: 100%;
    margin-left: @switch-sm-checked-margin-left;
  }

  &-small&-loading &-loading-icon {
    font-weight: bold;
    // animation: AntSwitchSmallLoadingCircle 1s infinite linear;
    transform: scale(0.66667);
  }

  &-checked {
    background-color: @switch-color;

    .@{switch-prefix-cls}-inner {
      margin-right: @switch-inner-margin-max;
      margin-left: @switch-inner-margin-min;
    }

    &::after {
      left: 100%;
      margin-left: -1px;
      transform: translateX(-100%);
    }
  }

  &-checked &-loading-icon {
    left: 100%;
    margin-left: -19px;
  }

  &-loading,
  &-disabled {
    cursor: not-allowed;
    opacity: @switch-disabled-opacity;
    * {
      cursor: not-allowed;
    }
    &::before,
    &::after {
      cursor: not-allowed;
    }
  }
}

@keyframes AntSwitchSmallLoadingCircle {
  0% {
    transform: rotate(0deg) scale(0.66667);
    transform-origin: 50% 50%;
  }
  100% {
    transform: rotate(360deg) scale(0.66667);
    transform-origin: 50% 50%;
  }
}

@import './rtl';
